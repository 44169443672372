import React, { useState, useRef, useEffect } from 'react';
import { useDB } from '../DBProvider';
import { analyzeImage, categorizeMealTime } from '../services/analyzeImage';
import '../css/UploadImage.css';
import EXIF from 'exif-js';
import ReactGA from "react-ga4";
import { supabase } from '../supabaseClient';
import logo from '../assets/calChef.png';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import { TrialManager, MAX_TRIAL_ANALYSES } from '../services/TrialManager';

export default function UploadImage({ setUploading, selectedDate, userProfile, setAllergyAlert }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const db = useDB();
  const [fileState, setFileState] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [localAllergyAlert, setLocalAllergyAlert] = useState(null);
  const imageContainerRef = useRef(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result); // Resolve the promise with the Base64 string
      };
      reader.onerror = reject; // Handle errors
      reader.readAsDataURL(file); // Read file as Base64 URL
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileState(file);
    if (file && file.type.startsWith('image/')) {
      if (previewURL) {
        URL.revokeObjectURL(previewURL);
        setPreviewURL(null);
      }
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          EXIF.getData(img, function () {
            const orientation = EXIF.getTag(this, "Orientation");
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            let width = img.width;
            let height = img.height;

            if (orientation > 4) {
              canvas.width = height;
              canvas.height = width;
            } else {
              canvas.width = width;
              canvas.height = height;
            }

            switch (orientation) {
              case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
              case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
              case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
              case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
              case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
              case 7: ctx.transform(0, -1, -1, 0, height, width); break;
              case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
              default: break;
            }

            ctx.drawImage(img, 0, 0, width, height);
            const correctedImageUrl = canvas.toDataURL('image/jpeg');
            setPreviewURL(correctedImageUrl);
          });
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      alert('Please select a valid image file');
    }
  };

  const calculateImage = async () => {
    setIsLoading(true);
    setUploading(true);
    
    if (!selectedImage) {
      alert('Please select an image first');
      setIsLoading(false);
      setUploading(false);
      return;
    }

    try {
      if (!user && !TrialManager.hasTrialsRemaining()) {
        const remainingTrials = TrialManager.getRemainingTrials();
        toast.error(`You've used all your trial analyses. Please sign up to continue using CalChef!`, {
          duration: 5000
        });
        navigate('/login');
        return;
      }

      if (fileState) {
        const base64Data = await fileToBase64(fileState);
        const result = await analyzeImage(base64Data, '', userProfile?.allergies);

        if (!user) {
          // Increment trial count for non-authenticated users
          const trialsRemaining = MAX_TRIAL_ANALYSES - TrialManager.incrementTrialCount();
          toast.success(`Analysis complete! You have ${trialsRemaining} trial analyses remaining.`, {
            duration: 3000
          });
        }

        const { data: { session } } = await supabase.auth.getSession();
        const userId = session?.user?.id;

        const uploadDateTime = new Date().toISOString();
        const mealCategory = categorizeMealTime(uploadDateTime);
        const dataToStore = {
          ...result,
          uploadDateTime: uploadDateTime,
          imageBlob: fileState,
          mealCategory: mealCategory,
          user_id: userId
        };

        const id = await db.addItem(dataToStore);
        console.log('Inserted Data id is ' + id);

        // Allergy alert handling
        if (result.allergy_warning &&
          result.allergy_warning.is_allergen_present &&
          userProfile?.allergies &&
          userProfile.allergies.toLowerCase() !== 'none') {
          setLocalAllergyAlert({
            allergens: result.allergy_warning.allergens_found,
            notes: result.allergy_warning.notes,
          });
        } else {
          setLocalAllergyAlert(null);
        }

        ReactGA.event({
          category: "User",
          action: "Uploaded Image",
          label: "Image Upload"
        });

        // Clear the image after successful analysis
        setSelectedImage(null);
        setPreviewURL(null);
        setFileState(null);
      }
    } catch (error) {
      console.error('Error processing image:', error);
      alert('An error occurred while processing the image. Please try again.');
    } finally {
      setIsLoading(false);
      setUploading(false);
    }
  };

  useEffect(() => {
    if (localAllergyAlert) {
      const timer = setTimeout(() => {
        setLocalAllergyAlert(null);
      }, 5000); // Hide the alert after 5 seconds

      return () => clearTimeout(timer);
    }
  }, [localAllergyAlert]);

  return (
    <div className='mt-4'>
      <div className='w-100'>
        <div className='card'>
          <div className='card-body'>
            <h2 className="h4 mb-4 text-primary"><i className="bi bi-upload me-2"></i>Upload Image</h2>
            <div className='mb-3'>
              {!previewURL && (
                <img 
                  src={logo}
                  alt="CalChef Logo" 
                  className="mb-4" 
                  style={{ maxWidth: '150px', height: 'auto', display: 'block', margin: '0 auto' }} 
                />
              )}
              <div className='position-relative' ref={imageContainerRef}>
                {previewURL && (
                  <div className='mb-3 text-center'>
                    <img 
                      src={previewURL}
                      alt='preview'
                      className='img-fluid'
                      style={{ maxHeight: '30vh' }}
                    />
                  </div>
                )}
                {localAllergyAlert && (
                  <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="alert alert-danger m-2" role="alert">
                      <h4 className="alert-heading">Allergy Warning!</h4>
                      <p><strong>This food may contain allergens you're sensitive to:</strong> {localAllergyAlert.allergens.join(', ')}.</p>
                      <hr />
                      <p className="mb-0">{localAllergyAlert.notes}</p>
                    </div>
                  </div>
                )}
              </div>
              <div className='d-flex justify-content-between'>
                <label htmlFor='file-upload' className='btn btn-outline-primary w-50 me-2'>
                  <i className="bi bi-image me-2"></i>
                  Choose Image
                </label>
                <label htmlFor='camera-capture' className='btn btn-outline-primary w-50 ms-2'>
                  <i className="bi bi-camera me-2"></i>
                  Capture Photo
                </label>
              </div>
              <input
                id='file-upload'
                type='file'
                accept='image/*'
                onChange={handleImageChange}
                className='d-none'
              />
              <input
                id='camera-capture'
                type='file'
                accept='image/*'
                capture
                onChange={handleImageChange}
                className='d-none'
              />
            </div>

            <button
              onClick={calculateImage}
              className='btn btn-primary w-100'
              disabled={!selectedImage || isLoading}
            >
              {isLoading ? (
                <>
                  <span
                    className='spinner-border spinner-border-sm me-2'
                    role='status'
                    aria-hidden='true'
                  ></span>
                  Calculating...
                </>
              ) : (
                'Analyze my Meal'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
