import React, { useState } from 'react';
import { DEFAULT_PROFILE, VALID_PROFILE_FIELDS } from '../../config/defaultProfile';
import WelcomeStep from './steps/WelcomeStep';
import BasicInfoStep from './steps/BasicInfoStep';
import HealthGoalsStep from './steps/HealthGoalsStep';
import DietaryPreferencesStep from './steps/DietaryPreferencesStep';
import ReviewStep from './steps/ReviewStep';

const OnboardingFlow = ({ onComplete, user }) => {
  const [step, setStep] = useState(0);
  const [profile, setProfile] = useState(DEFAULT_PROFILE);

  const steps = [
    {
      component: WelcomeStep,
      title: "Welcome to CalChef"
    },
    {
      component: BasicInfoStep,
      title: "Basic Information"
    },
    {
      component: HealthGoalsStep,
      title: "Health Goals"
    },
    {
      component: DietaryPreferencesStep,
      title: "Dietary Preferences"
    },
    {
      component: ReviewStep,
      title: "Review"
    }
  ];

  const CurrentStep = steps[step].component;

  const handleNext = (data) => {
    const updatedProfile = { ...profile, ...data };
    setProfile(updatedProfile);
    
    if (step < steps.length - 1) {
      setStep(prev => prev + 1);
    } else {
      const validProfileData = {
        ...VALID_PROFILE_FIELDS.reduce((acc, field) => {
          if (updatedProfile[field] !== undefined) {
            acc[field] = updatedProfile[field];
          }
          return acc;
        }, {}),
        user_id: user.id
      };
      
      onComplete(validProfileData);
    }
  };

  const handleBack = () => {
    setStep(prev => prev - 1);
  };

  const progressPercentage = ((step + 1) / steps.length) * 100;

  return (
    <div className="onboarding-container">
      <div className="progress mb-4">
        <div 
          className="progress-bar" 
          role="progressbar" 
          style={{ width: `${progressPercentage}%` }}
          aria-valuenow={progressPercentage} 
          aria-valuemin="0" 
          aria-valuemax="100"
        />
      </div>
      
      <h2 className="text-center mb-4">{steps[step].title}</h2>
      
      <CurrentStep 
        data={profile}
        onNext={handleNext}
        onBack={handleBack}
        isFirstStep={step === 0}
        isLastStep={step === steps.length - 1}
      />
    </div>
  );
};

export default OnboardingFlow; 