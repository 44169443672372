import React, { useState } from 'react';
import { motion } from 'framer-motion';

const HealthGoalsStep = ({ data, onNext, onBack }) => {
  const [formData, setFormData] = useState({
    health_goals: data.health_goals || '',
    target_weight: data.target_weight || '',
    weekly_exercise_days: data.weekly_exercise_days || 3,
    dietary_focus: data.dietary_focus || []
  });

  const dietaryFocusOptions = [
    { value: 'weight_loss', label: 'Weight Loss' },
    { value: 'muscle_gain', label: 'Muscle Gain' },
    { value: 'maintenance', label: 'Maintenance' },
    { value: 'heart_health', label: 'Heart Health' },
    { value: 'balanced_nutrition', label: 'Balanced Nutrition' }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext(formData);
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -20 }}
    >
      <form onSubmit={handleSubmit} className="needs-validation">
        <div className="mb-4">
          <label className="form-label">What are your health goals?</label>
          <textarea
            className="form-control"
            value={formData.health_goals}
            onChange={(e) => setFormData(prev => ({ ...prev, health_goals: e.target.value }))}
            placeholder="E.g., lose weight, build muscle, maintain health..."
            rows="3"
          />
        </div>

        <div className="mb-4">
          <label className="form-label">Dietary Focus</label>
          <div className="d-flex flex-wrap gap-2">
            {dietaryFocusOptions.map(option => (
              <div key={option.value} className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={option.value}
                  checked={formData.dietary_focus.includes(option.value)}
                  onChange={(e) => {
                    const newFocus = e.target.checked
                      ? [...formData.dietary_focus, option.value]
                      : formData.dietary_focus.filter(f => f !== option.value);
                    setFormData(prev => ({ ...prev, dietary_focus: newFocus }));
                  }}
                />
                <label className="form-check-label" htmlFor={option.value}>
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className="d-flex justify-content-between mt-4">
          <button 
            type="button" 
            className="btn btn-outline-secondary" 
            onClick={onBack}
          >
            Back
          </button>
          <button 
            type="submit" 
            className="btn btn-primary"
          >
            Next
          </button>
        </div>
      </form>
    </motion.div>
  );
};

export default HealthGoalsStep; 