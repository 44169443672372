import React, { useState } from 'react';
import { useDB } from './DBProvider';

const Purge = () => {
  const [purgeOption, setPurgeOption] = useState('all');
  const [purgeAmount, setPurgeAmount] = useState(1);
  const [purgeTimeframe, setPurgeTimeframe] = useState(24);
  const [purgeResult, setPurgeResult] = useState('');
  const db = useDB();

  const handlePurge = async () => {
    if (!db) {
      setPurgeResult('Database not initialized');
      return;
    }

    try {
      const allItems = await db.getAllItems();
      const foodItems = allItems.filter(item => 
        item.type !== 'userProfile' && item.type !== 'savedRecipes'
      );
      let itemsToPurge = [];

      switch (purgeOption) {
        case 'all':
          itemsToPurge = foodItems;
          break;
        case 'last':
          itemsToPurge = foodItems.slice(-purgeAmount);
          break;
        case 'timeframe':
          const cutoffTime = new Date(Date.now() - purgeTimeframe * 60 * 60 * 1000);
          itemsToPurge = foodItems.filter(item => new Date(item.uploadDateTime) > cutoffTime);
          break;
        default:
          setPurgeResult('Invalid purge option');
          return;
      }

      console.log(`Attempting to purge ${itemsToPurge.length} items`);

      for (const item of itemsToPurge) {
        await db.deleteItem(item.id);
        console.log(`Deleted item with ID: ${item.id}`);
      }

      setPurgeResult(`Successfully purged ${itemsToPurge.length} items`);
      
      // Trigger a refresh of the main App component
      if (typeof window !== 'undefined' && window.refreshApp) {
        window.refreshApp();
      }
    } catch (error) {
      console.error('Error during purge:', error);
      setPurgeResult(`Error during purge: ${error.message}`);
    }
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Purge Database</h2>
      <div className="mb-3">
        <select 
          className="form-select" 
          value={purgeOption} 
          onChange={(e) => setPurgeOption(e.target.value)}
        >
          <option value="all">All entries</option>
          <option value="last">Last X entries</option>
          <option value="timeframe">Entries within timeframe</option>
        </select>
      </div>
      {purgeOption === 'last' && (
        <div className="mb-3">
          <label className="form-label">Number of entries to purge:</label>
          <input 
            type="number" 
            className="form-control" 
            value={purgeAmount} 
            onChange={(e) => setPurgeAmount(parseInt(e.target.value))}
            min="1"
          />
        </div>
      )}
      {purgeOption === 'timeframe' && (
        <div className="mb-3">
          <label className="form-label">Hours to look back:</label>
          <input 
            type="number" 
            className="form-control" 
            value={purgeTimeframe} 
            onChange={(e) => setPurgeTimeframe(parseInt(e.target.value))}
            min="1"
          />
        </div>
      )}
      <button className="btn btn-danger" onClick={handlePurge}>Purge</button>
      {purgeResult && (
        <div className="alert alert-info mt-3" role="alert">
          {purgeResult}
        </div>
      )}
    </div>
  );
};

export default Purge;
