export class SyncRecord {
    constructor(data) {
      this.id = data.id
      this.version = data.version || 0
      this.lastModified = data.lastModified || new Date().toISOString()
      this.syncStatus = data.syncStatus || 'pending'
      this.operation = data.operation
      this.retryCount = data.retryCount || 0
      this.data = data
    }
  
    incrementVersion() {
      this.version++
      this.lastModified = new Date().toISOString()
      return this
    }
  
    markAsSynced() {
      this.syncStatus = 'synced'
      return this
    }
  
    markAsError() {
      this.syncStatus = 'error'
      this.retryCount++
      return this
    }
  } 