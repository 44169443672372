import React, { useState, useEffect } from 'react';
import { analyzeImage, generateRecipe, analyzeMicronutrients, categorizeMealTime } from '../services/analyzeImage';
import { useDB } from '../DBProvider';
import Modal from 'react-bootstrap/Modal';
//import './ConsumptionHistoryItem.css';

const ConsumptionHistoryItem = ({ item, onDelete, onUpdate, refreshSavedRecipes, hasAllergyAlert, allergens, isExpanded, setIsExpanded }) => {
  console.log('ConsumptionHistoryItem received item:', item);

  const [expanded, setExpanded] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editName, setEditName] = useState(item.name || item.short_name || '');
  const [editDate, setEditDate] = useState('');
  const [editTime, setEditTime] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [recipe, setRecipe] = useState(item.recipe || null);
  const [isRecipeVisible, setIsRecipeVisible] = useState(false);
  const [isGeneratingRecipe, setIsGeneratingRecipe] = useState(false);
  const db = useDB();
  const [activeTab, setActiveTab] = useState('nutrition');
  const [micronutrients, setMicronutrients] = useState(null);
  const [loadingMicronutrients, setLoadingMicronutrients] = useState(false);
  const [mealCategory, setMealCategory] = useState('');
  const [showAllergyAlert, setShowAllergyAlert] = useState(false);

  useEffect(() => {
    if (item.imageBlob) {
      console.log('Creating object URL for image blob');
      const url = URL.createObjectURL(item.imageBlob);
      setImageURL(url);
      return () => {
        URL.revokeObjectURL(url);
      };
    } else {
      console.log('No image blob found for item');
    }
  }, [item.imageBlob]);

  useEffect(() => {
    if (item.uploadDateTime) {
      const category = item.mealCategory || categorizeMealTime(item.uploadDateTime);
      setMealCategory(category);
    }
  }, [item.uploadDateTime, item.mealCategory]);

  const formatDate = (dateString) => {
    if (!dateString || isNaN(Date.parse(dateString))) {
      console.warn(`Invalid dateString: ${dateString}`);
      return 'Invalid Date';
    }

    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' }).slice(0, 3);
    return `${day} ${month}`;
  };


  // Helper function to format date to YYYY-MM-DD
  const formatDateForInput = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  // Add a new function to format time for input
  const formatTimeForInput = (dateString) => {
    const date = new Date(dateString);
    return date.toTimeString().slice(0, 5); // Returns time in HH:MM format
  };

  const handleEdit = () => {
    setIsEditing(true);
    setEditName(item.name || item.short_name || '');
    setEditDate(formatDateForInput(item.uploadDateTime));
    setEditTime(formatTimeForInput(item.uploadDateTime));
  };

  const handleUpdate = async () => {
    setIsUploading(true);
    try {
      const response = await fetch(item.imageURL);
      const blob = await response.blob();
      const reader = new FileReader();

      reader.onloadend = async () => {
        const base64data = reader.result;
        const updatedAnalysis = await analyzeImage(base64data, editName);
        if (updatedAnalysis) {
          const combinedDateTime = `${editDate}T${editTime}:00`;
          const newUploadDateTime = new Date(combinedDateTime).toISOString();
          const updatedItem = {
            ...item,
            ...updatedAnalysis,
            name: editName,
            imageBlob: blob,
            uploadDateTime: newUploadDateTime,
            mealCategory: categorizeMealTime(newUploadDateTime),
          };
          await onUpdate(updatedItem);
          setIsEditing(false);
          setRecipe(updatedAnalysis.recipe);
          setExpanded(false);
          setTimeout(() => setExpanded(true), 0);
        } else {
          console.error('Failed to update analysis');
        }
        setIsUploading(false);
      };

      reader.readAsDataURL(blob);
    } catch (error) {
      console.error('Error updating entry:', error);
      setIsUploading(false);
    }
  };

  const handleDelete = () => {
    onDelete(item.id);
    if (setIsExpanded) {
      setIsExpanded(false);
      setTimeout(() => setIsExpanded(true), 0);
    }
  };

  const toggleRecipeVisibility = () => {
    setIsRecipeVisible(!isRecipeVisible);
  };

  const handleSaveRecipe = async () => {
    try {
      const imageURL = item.imageBlob ? URL.createObjectURL(item.imageBlob) : null;
      
      const recipeItem = {
        ...item,
        type: 'savedRecipes',
        recipe: {
          ...recipe,
          imageBlob: item.imageBlob,
          imageURL: imageURL
        },
        imageBlob: item.imageBlob,
        imageURL: imageURL
      };
      
      await db.updateItem(recipeItem);
      refreshSavedRecipes();
      setIsRecipeVisible(false);
    } catch (error) {
      console.error('Error saving recipe:', error);
    }
  };

  const handleGenerateRecipe = async () => {
    setIsGeneratingRecipe(true);
    try {
      const allItems = await db.getAllItems();
      const userProfile = allItems.find(item => item.type === 'userProfile');
      const dietaryRequirements = userProfile ? userProfile.dietaryRequirements : '';

      const generatedRecipe = await generateRecipe(
        item.name || item.description,
        item.description,
        dietaryRequirements
      );

      if (generatedRecipe && typeof generatedRecipe === 'object') {
        const formattedRecipe = {
          ...generatedRecipe,
          imageBlob: item.imageBlob,
          imageURL: item.imageURL
        };

        const updatedItem = {
          ...item,
          recipe: formattedRecipe,
          imageBlob: item.imageBlob,
          imageURL: item.imageURL
        };
        
        setRecipe(formattedRecipe);
        setIsRecipeVisible(true);
        await onUpdate(updatedItem);
      }
    } catch (error) {
      console.error('Error generating recipe:', error);
    } finally {
      setIsGeneratingRecipe(false);
    }
  };

  const getShortTitle = () => {
    if (item.short_name) return item.short_name;
    if (item.name) return item.name.length > 20 ? item.name.substring(0, 17) + '...' : item.name;
    if (item.description) {
      const words = item.description.split(' ');
      return words.length > 3 ? words.slice(0, 3).join(' ') + '...' : item.description;
    }
    return 'Untitled Item';
  };

  const shortTitle = getShortTitle();

  const kilojoules = Math.round(item.calories * 4.184); // Convert calories to kilojoules

  const formatRecipe = (recipe) => {
    if (!recipe) return null;

    return (
      <div>
        <h4>{recipe.title || 'Untitled Recipe'}</h4>
        
        {recipe.servings && recipe.servings.length > 0 && (
          <div className="mb-3">
            <h5>Servings</h5>
            <ul>
              {recipe.servings.map((serving, index) => (
                <li key={index}>{serving}</li>
              ))}
            </ul>
          </div>
        )}

        {recipe.ingredients && recipe.ingredients.length > 0 && (
          <div className="mb-3">
            <h5>Ingredients</h5>
            <ul>
              {recipe.ingredients.map((ingredient, index) => (
                <li key={index}>{ingredient}</li>
              ))}
            </ul>
          </div>
        )}

        {recipe.instructions && recipe.instructions.length > 0 && (
          <div className="mb-3">
            <h5>Instructions</h5>
            <ol>
              {recipe.instructions.map((instruction, index) => (
                <li key={index}>{instruction}</li>
              ))}
            </ol>
          </div>
        )}

        {recipe.cultural_context && (
          <div className="mb-3">
            <h5>Cultural Context</h5>
            <p>{recipe.cultural_context}</p>
          </div>
        )}

        {recipe.tips && recipe.tips.length > 0 && (
          <div className="mb-3">
            <h5>Tips</h5>
            <ul>
              {recipe.tips.map((tip, index) => (
                <li key={index}>{tip}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  };

  const handleMicronutrientTabClick = async () => {
    setActiveTab('micronutrients');
    if (!micronutrients && !loadingMicronutrients) {
      setLoadingMicronutrients(true);
      try {
        const result = await analyzeMicronutrients(item.name, item.description);
        setMicronutrients(result.micronutrients);
      } catch (error) {
        console.error('Error analyzing micronutrients:', error);
      } finally {
        setLoadingMicronutrients(false);
      }
    }
  };

  const calculateAdjustedMicronutrients = (micronutrients, servingSize) => {
    const portionInGrams = parseServingSize(servingSize);
    const multiplier = portionInGrams / 100; // since base values are per 100g

    return Object.entries(micronutrients).reduce((acc, [nutrient, value]) => {
      acc[nutrient] = adjustNutrientValue(value, multiplier);
      return acc;
    }, {});
  };

  // Helper function to parse serving size
  const parseServingSize = (servingSize) => {
    const match = servingSize.match(/(\d+(\.\d+)?)/);
    return match ? parseFloat(match[0]) : 1;
  };

  // Helper function to adjust nutrient value
  const adjustNutrientValue = (value, multiplier) => {
    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) return value;
    return (numericValue * multiplier).toFixed(2) + value.replace(/[\d.]+/, '');
  };

  const adjustedMicronutrients = micronutrients
    ? calculateAdjustedMicronutrients(micronutrients, item.serving_size)
    : null;

  const handleAllergyAlertClick = (e) => {
    e.stopPropagation();
    setShowAllergyAlert(true);
  };

  const toggleExpand = () => {
    if (setIsExpanded) {
      setIsExpanded(!isExpanded);
    } else {
      setExpanded(!expanded);
    }
  };

  const isItemExpanded = isExpanded !== undefined ? isExpanded : expanded;

  return (
    <div className="card mb-3 shadow-sm consumption-history-item">
      <button
        className="delete-btn"
        onClick={(e) => {
          e.stopPropagation();
          handleDelete();
        }}
        aria-label="Delete item"
      >
        <i className="bi bi-x-lg"></i>
      </button>
      {hasAllergyAlert && (
        <button
          className="allergy-alert-btn"
          onClick={handleAllergyAlertClick}
          aria-label="Allergy Alert"
        >
          <i className="bi bi-exclamation-triangle-fill text-warning"></i>
        </button>
      )}
      <div
        className="card-header bg-light p-0"
        role="button"
        onClick={toggleExpand}
      >
        <div className="d-flex align-items-center">
          {item.imageURL && (
            <div className="flex-shrink-0">
              <img
                src={item.imageURL}
                alt={shortTitle}
                className="rounded-start"
                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
              />
            </div>
          )}
          <div className="flex-grow-1 ms-3 py-2">
            <h5 className="mb-1">{shortTitle}</h5>
            <p className="mb-0 text-muted small">
              <i className="bi bi-calendar-event me-2"></i>
              {formatDate(item.uploadDateTime)}
            </p>
            <p className="mb-0 mt-1"> {/* add badges here */}
              <span className="badge bg-primary me-2">{item.calories} kcal</span>
              <span className="badge bg-secondary me-2">{item.serving_size}</span>
            </p>
          </div>
          <div className="p-3">
            <i className={`bi bi-chevron-${isItemExpanded ? 'up' : 'down'} fs-4`}></i>
          </div>
        </div>
      </div>

      {isItemExpanded && (
        <div className="card-body">
          {isEditing ? (
            <form onSubmit={(e) => {
              e.preventDefault();
              handleUpdate();
            }}>
              <div className="mb-3">
                <label htmlFor="editName" className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="editName"
                  value={editName}
                  onChange={(e) => setEditName(e.target.value)}
                />
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="editDate" className="form-label">Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="editDate"
                    value={editDate}
                    onChange={(e) => setEditDate(e.target.value)}
                  />
                </div>
                <div className="col">
                  <label htmlFor="editTime" className="form-label">Time</label>
                  <input
                    type="time"
                    className="form-control"
                    id="editTime"
                    value={editTime}
                    onChange={(e) => setEditTime(e.target.value)}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button type="button" className="btn btn-outline-secondary me-2" onClick={() => setIsEditing(false)}>
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary" disabled={isUploading}>
                  {isUploading ? 'Updating...' : 'Save Changes'}
                </button>
              </div>
            </form>
          ) : (
            <>
              {item.imageURL && (
                <img
                  src={item.imageURL}
                  alt={item.name || item.description}
                  className="img-fluid rounded mb-3"
                  style={{ maxHeight: '300px', width: '100%', objectFit: 'cover' }}
                />
              )}

              <ul className="nav nav-tabs mb-3">
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === 'nutrition' ? 'active' : ''}`}
                    onClick={() => setActiveTab('nutrition')}
                  >
                    Nutrition & Macros
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === 'micronutrients' ? 'active' : ''}`}
                    onClick={handleMicronutrientTabClick}
                  >
                    Micronutrients
                  </button>
                </li>
              </ul>

              {activeTab === 'nutrition' && (
                <div className="row mb-4">
                  <div className="col-md-6">
                    <h5 className="border-bottom pb-2 mb-3">Nutritional Information</h5>
                    <div className="d-flex justify-content-between mb-2">
                      <span>Calories:</span>
                      <strong>{item.calories} kcal</strong>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <span>Kilojoules:</span>
                      <strong>{kilojoules} kJ</strong>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <span>Serving Size:</span>
                      <strong>{item.serving_size}</strong>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <span>Cholesterol:</span>
                      <strong>{item.cholesterol} mg</strong>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <span>Sodium:</span>
                      <strong>{item.sodium} mg</strong>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h5 className="border-bottom pb-2 mb-3">Macronutrients</h5>
                    {Object.entries(item.macronutrients).map(([nutrient, value]) => (
                      <div key={nutrient} className="d-flex justify-content-between mb-2">
                        <span>{nutrient.charAt(0).toUpperCase() + nutrient.slice(1)}:</span>
                        <strong>{value} g</strong>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {activeTab === 'micronutrients' && (
                <div className="mb-4">
                  <h5 className="border-bottom pb-2 mb-3">Micronutrients</h5>
                  <p>Serving size: {item.serving_size}</p>
                  {loadingMicronutrients ? (
                    <p>Loading micronutrient data...</p>
                  ) : adjustedMicronutrients ? (
                    <ul className="list-unstyled">
                      {Object.entries(adjustedMicronutrients).map(([nutrient, value]) => (
                        <li key={nutrient} className="d-flex justify-content-between mb-2">
                          <span>{nutrient}:</span>
                          <strong>{value}</strong>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No micronutrient data available.</p>
                  )}
                </div>
              )}

              <div className="mb-4">
                <h5 className="border-bottom pb-2 mb-3">Description</h5>
                <p>{item.description}</p>
              </div>

              <div className="bg-light p-3 rounded mb-4">
                <h5 className="mb-3">
                  <i className="bi bi-activity me-2"></i>
                  Exercise Equivalent
                </h5>
                <p className="mb-1">
                  <strong>Walking:</strong> {item.exercise_equivalent.walking_minutes} minutes
                </p>
                <p className="text-muted small mb-0">{item.exercise_equivalent.notes}</p>
              </div>

              <div className="d-flex justify-content-end">
                <button className="btn btn-outline-primary btn-sm me-2" onClick={handleEdit}>
                  <i className="bi bi-pencil me-1"></i> Edit Food
                </button>
                {!recipe && (
                  <button
                    className="btn btn-outline-success btn-sm me-2"
                    onClick={handleGenerateRecipe}
                    disabled={isGeneratingRecipe}
                  >
                    <i className="bi bi-book me-1"></i>
                    {isGeneratingRecipe ? 'Generating...' : 'Generate Recipe'}
                  </button>
                )}
                {recipe && (
                  <button
                    className="btn btn-outline-primary btn-sm me-2"
                    onClick={handleSaveRecipe}
                  >
                    <i className="bi bi-bookmark-plus me-1"></i> Save Recipe
                  </button>
                )}
                <button className="btn btn-outline-danger btn-sm" onClick={handleDelete}>
                  <i className="bi bi-trash me-1"></i> Delete
                </button>
              </div>

              {recipe && (
                <div className="mt-4 recipe-container">
                  <h5 className="border-bottom pb-2 mb-3 d-flex justify-content-between align-items-center">
                    Generated Recipe
                    <button
                      className="btn btn-outline-secondary btn-sm"
                      onClick={toggleRecipeVisibility}
                    >
                      {isRecipeVisible ? 'Hide' : 'Show'}
                    </button>
                  </h5>
                  {isRecipeVisible && (
                    <div className={`recipe-content ${isRecipeVisible ? '' : 'collapsed'}`}>
                      {formatRecipe(recipe)}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}

      <Modal show={showAllergyAlert} onHide={() => setShowAllergyAlert(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Allergy Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>This food may contain allergens you're sensitive to:</strong></p>
          {allergens && allergens.length > 0 ? (
            <ul>
              {allergens.map((allergen, index) => (
                <li key={index}>{allergen}</li>
              ))}
            </ul>
          ) : (
            <p>No specific allergens identified.</p>
          )}
          <p>Please be cautious when consuming this food.</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setShowAllergyAlert(false)}>Close</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConsumptionHistoryItem;
