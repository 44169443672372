import React from 'react';
import { motion } from 'framer-motion';
import { VALID_PROFILE_FIELDS } from '../../../config/defaultProfile';

const ReviewStep = ({ data, onNext, onBack }) => {
  // Fields to display in review, mapped to friendly names
  const reviewFields = {
    name: 'Name',
    sex: 'Sex',
    age: 'Age',
    height: 'Height (cm)',
    weight: 'Weight (kg)',
    activity_level: 'Activity Level',
    dietary_requirements: 'Dietary Requirements',
    allergies: 'Allergies'
  };

  const formatValue = (key, value) => {
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    return value || 'Not specified';
  };

  // Filter data to only include valid fields
  const validData = VALID_PROFILE_FIELDS.reduce((acc, field) => {
    if (data[field] !== undefined) {
      acc[field] = data[field];
    }
    return acc;
  }, {});

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -20 }}
    >
      <div className="review-step">
        <div className="alert alert-info mb-4">
          <i className="bi bi-info-circle me-2"></i>
          Please review your profile information before finishing.
        </div>

        <div className="card mb-4">
          <div className="card-body">
            <h5 className="card-title mb-4">Profile Summary</h5>
            <div className="row">
              {Object.entries(reviewFields).map(([key, label]) => (
                validData[key] && (
                  <div key={key} className="col-md-6 mb-3">
                    <div className="review-item">
                      <strong>{label}:</strong>
                      <div className="text-muted">
                        {formatValue(key, validData[key])}
                      </div>
                    </div>
                  </div>
                )
              ))}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between mt-4">
          <button 
            type="button" 
            className="btn btn-outline-secondary" 
            onClick={onBack}
          >
            Back
          </button>
          <button 
            type="button" 
            className="btn btn-primary"
            onClick={() => onNext(validData)}
          >
            Complete Setup
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default ReviewStep; 