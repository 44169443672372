import React, { useState, useEffect } from 'react';
import { useDB } from '../DBProvider';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import kalamBoldFont from '../fonts/Kalam-Bold.ttf';
import openDyslexicFont from '../fonts/OpenDyslexic-Regular.otf';
import openDyslexicBoldFont from '../fonts/OpenDyslexic-Bold.otf';
import RecipeCard from './RecipeCard';
import RecipeModal from './RecipeModal';
import SearchBar from './SearchBar';

const SavedRecipes = () => {
  const [savedRecipes, setSavedRecipes] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const db = useDB();

  useEffect(() => {
    const fetchSavedRecipes = async () => {
      if (db) {
        try {
          const recipes = await db.getAllItems();
          console.log('Raw recipes:', recipes); // Add this log
          const recipesWithImageURLs = recipes
            .filter(item => item.type === 'savedRecipes' && item.recipe)
            .map(item => {
              const imageURL = item.imageBlob ? URL.createObjectURL(item.imageBlob) : null;
              return {
                ...item,
                title: item.recipe.title || item.name || 'Untitled Recipe',
                imageURL: imageURL,
                imageBlob: item.imageBlob || item.recipe.imageBlob,
                // Include recipe properties
                servings: item.recipe.servings || [],
                ingredients: item.recipe.ingredients || [],
                instructions: item.recipe.instructions || [],
                cultural_context: item.recipe.cultural_context || '',
                tips: item.recipe.tips || [],
                cooking_time: item.recipe.cooking_time || 'Not specified',
                // Include analysis data
                calories: item.calories,
                macronutrients: item.macronutrients,
                serving_size: item.serving_size
              };
            })
            .sort((a, b) => (a.title || '').localeCompare(b.title || ''));
          setSavedRecipes(recipesWithImageURLs);
          setFilteredRecipes(recipesWithImageURLs);
        } catch (error) {
          console.error('Error fetching saved recipes:', error);
        }
      }
    };
    fetchSavedRecipes();

    return () => {
      savedRecipes.forEach(recipe => {
        if (recipe.imageURL) {
          URL.revokeObjectURL(recipe.imageURL);
        }
      });
    };
  }, [db]);

  useEffect(() => {
    const filtered = savedRecipes.filter(recipe =>
      (recipe.title || '').toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRecipes(filtered);
  }, [searchTerm, savedRecipes]);

  const handleDelete = async (id) => {
    if (db) {
      try {
        await db.deleteItem(id);
        setSavedRecipes(savedRecipes.filter(recipe => recipe.id !== id));
      } catch (error) {
        console.error('Error deleting recipe:', error);
      }
    }
  };

  const downloadRecipe = async (recipe) => {
    const doc = new jsPDF();

    // Add fonts with error handling
    try {
      doc.addFont(kalamBoldFont, "Kalam", "bold");
      doc.addFont(openDyslexicFont, "OpenDyslexic", "normal");
      doc.addFont(openDyslexicBoldFont, "OpenDyslexic", "bold");
    } catch (error) {
      console.error("Error loading custom fonts:", error);
      // Fallback to default fonts
      doc.setFont("helvetica", "bold");
    }

    const addFancyBorder = () => {
      doc.setDrawColor(41, 128, 185);
      doc.setLineWidth(0.5);
      doc.roundedRect(10, 10, 190, 277, 3, 3);
      doc.setLineWidth(0.2);
      doc.roundedRect(13, 13, 184, 271, 2, 2);
    };

    addFancyBorder();

    let yOffset = 20;

    // Add image if available
    if (recipe.imageURL) {
      const img = new Image();
      img.src = recipe.imageURL;
      await new Promise((resolve) => {
        img.onload = resolve;
      });
      const imgWidth = 100;
      const imgHeight = (img.height * imgWidth) / img.width;
      doc.addImage(img, 'JPEG', 55, yOffset, imgWidth, imgHeight);
      yOffset += imgHeight + 15;
    }

    // Title
    doc.setFontSize(24);
    doc.setTextColor(41, 128, 185);
    doc.setFont("Kalam", "bold");  // Changed to use Kalam bold
    const titleLines = doc.splitTextToSize(recipe.title, 170);
    doc.text(titleLines, 105, yOffset, { align: 'center' });
    yOffset += 10 * titleLines.length + 5;

    // Cooking Time
    doc.setFontSize(14);
    doc.setTextColor(100);
    doc.setFont("helvetica", "normal");
    doc.text(`Cooking Time: ${recipe.cooking_time || 'N/A'}`, 105, yOffset, { align: 'center' });
    yOffset += 15;

    const addSection = (title, content, isArray = false) => {
      doc.autoTable({
        startY: yOffset,
        head: [[title]],
        body: isArray ? content.map(item => [item]) : [[content]],
        theme: 'plain',
        headStyles: {
          fillColor: [240, 248, 255],
          textColor: [0, 0, 0],
          fontStyle: 'bold',
          fontSize: 14,
          cellPadding: { top: 3, right: 8, bottom: 3, left: 8 },
          minCellHeight: 10,
          font: 'Kalam'
        },
        bodyStyles: {
          font: 'helvetica',
          fontSize: 12,
        },
        columnStyles: { 0: { cellWidth: 170 } },
        margin: { left: 20, right: 20 },
      });
      yOffset = doc.lastAutoTable.finalY + 10;

      if (yOffset > 260) {
        doc.addPage();
        addFancyBorder();
        yOffset = 20;
      }
    };

    addSection("Ingredients", recipe.ingredients, true);
    addSection("Servings", recipe.servings, true);
    addSection("Instructions", recipe.instructions.map((instr, idx) => `${idx + 1}. ${instr}`), true);
    addSection("Cultural Context", recipe.cultural_context);
    addSection("Tips", recipe.tips, true);

    // Add page numbers
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setTextColor(150);
      doc.setFont("helvetica", "normal");
      doc.text(`Page ${i} of ${pageCount}`, 105, 285, { align: 'center' });
    }

    doc.save(`${recipe.title}.pdf`);
  };

  return (
    <div className="saved-recipes mb-5">
      <h2 className="h4 mb-4 text-primary">
        <i className="bi bi-book me-2"></i>Saved Recipes
      </h2>
      <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <div className="recipe-grid">
        {filteredRecipes.map((recipe) => (
          <RecipeCard
            key={recipe.id}
            recipe={recipe}
            onDelete={handleDelete}
            onDownload={downloadRecipe}
            onClick={() => setSelectedRecipe(recipe)}
          />
        ))}
      </div>
      {selectedRecipe && (
        <RecipeModal
          recipe={selectedRecipe}
          onClose={() => setSelectedRecipe(null)}
          onDelete={handleDelete}
          onDownload={downloadRecipe}
        />
      )}
    </div>
  );
};

export default SavedRecipes;
