import React from 'react';
import { motion } from 'framer-motion';
import logo from '../../../assets/calChef.png';

const WelcomeStep = ({ onNext }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="text-center"
    >
      <img 
        src={logo}
        alt="CalChef Logo" 
        className="mb-4" 
        style={{ maxWidth: '150px', height: 'auto' }} 
      />
      <p className="mb-4">
        Let's set up your profile so you can track your nutrition and achieve your health goals.
      </p>
      <button 
        className="btn btn-primary btn-lg"
        onClick={() => onNext({})}
      >
        Get Started
      </button>
    </motion.div>
  );
};

export default WelcomeStep; 