import { categorizeMealTime } from './services/analyzeImage';

export const nutritionTypes = [
  { name: 'carbohydrates', displayName: 'Carbohydrates', unit: 'g', caloriesPerUnit: 4 },
  { name: 'protein', displayName: 'Protein', unit: 'g', caloriesPerUnit: 4 },
  { name: 'fats', displayName: 'Fats', unit: 'g', caloriesPerUnit: 9 },
  { name: 'fiber', displayName: 'Fiber', unit: 'g', caloriesPerUnit: 0 },
  { name: 'sugar', displayName: 'Sugar', unit: 'g', caloriesPerUnit: 4 },
];

/**
 * A data class for a single piece of food consumed, includes its nutritional value
 */
class ConsumedFood {
  constructor(data) {
    this.id = data.id
    this.name = data.name || this.generateName(data.description)
    this.short_name = data.short_name || this.generateShortName(this.name)
    this.description = data.description || ''
    this.calories = this.parseNutrition(data.calories)
    this.serving_size = data.serving_size || ''
    this.confidence_score = data.confidence_score || 0
    this.macronutrients = {
      carbohydrates: this.parseNutrition(data.macronutrients?.carbohydrates || data.carbohydrates),
      protein: this.parseNutrition(data.macronutrients?.protein || data.protein),
      fats: this.parseNutrition(data.macronutrients?.fats || data.fats),
      fiber: this.parseNutrition(data.macronutrients?.fiber || data.fiber),
      sugar: this.parseNutrition(data.macronutrients?.sugar || data.sugar),
    }
    this.cholesterol = this.parseNutrition(data.cholesterol);
    this.sodium = this.parseNutrition(data.sodium);
    this.exercise_equivalent = data.exercise_equivalent || {
      walking_minutes: 0,
      notes: '',
    }
    this.uploadDateTime = data.uploadDateTime || data.upload_date_time || new Date().toISOString()
    this.imageURL = data.imageURL || null
    this.mealCategory = data.mealCategory || data.meal_category || categorizeMealTime(this.uploadDateTime)

    console.log('ConsumedFood created:', {
      id: this.id,
      name: this.name,
      calories: this.calories,
      macronutrients: this.macronutrients,
      cholesterol: this.cholesterol,
      sodium: this.sodium,
      mealCategory: this.mealCategory,
    });
  }

  generateName(description) {
    if (!description) return 'Untitled Item';
    const words = description.split(' ');
    if (words.length > 3) {
      return words.slice(0, 3).join(' ') + '...';
    }
    return description;
  }

  generateShortName(name) {
    if (!name) return 'Untitled';
    const words = name.split(' ');
    if (words.length > 3) {
      return words.slice(0, 3).join(' ');
    }
    return name;
  }

  parseNutrition(value) {
    if (typeof value === 'string') {
      // Remove any non-numeric characters except decimal point
      value = value.replace(/[^\d.]/g, '');
      const numericValue = parseFloat(value);
      return isNaN(numericValue) ? 0 : numericValue;
    }
    return typeof value === 'number' ? value : 0;
  }
}

export default ConsumedFood
