const TRIAL_STORAGE_KEY = 'trial_usage_count';
const MAX_TRIAL_ANALYSES = 3;

export { MAX_TRIAL_ANALYSES };

export const TrialManager = {
  getTrialCount() {
    return parseInt(localStorage.getItem(TRIAL_STORAGE_KEY) || '0');
  },

  incrementTrialCount() {
    const currentCount = this.getTrialCount();
    localStorage.setItem(TRIAL_STORAGE_KEY, (currentCount + 1).toString());
    return currentCount + 1;
  },

  getRemainingTrials() {
    return MAX_TRIAL_ANALYSES - this.getTrialCount();
  },

  hasTrialsRemaining() {
    return this.getTrialCount() < MAX_TRIAL_ANALYSES;
  },

  resetTrialCount() {
    localStorage.removeItem(TRIAL_STORAGE_KEY);
  }
}; 