import React, { useState } from 'react';

const RecipeModal = ({ recipe, onClose, onDelete, onDownload }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  if (!recipe || typeof recipe !== 'object') {
    return null;
  }

  const {
    title = 'Untitled Recipe',
    servings = [],
    ingredients = [],
    instructions = [],
    cultural_context = '',
    tips = [],
    cooking_time = 'Not specified',
    imageURL
  } = recipe;

  return (
    <div className="recipe-modal-overlay" onClick={onClose}>
      <div className="recipe-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>×</button>
        <h2>{title}</h2>
        
        {imageURL && (
          <div className="recipe-image-container" style={{
            width: '100%',
            maxHeight: '300px',
            overflow: 'hidden',
            marginBottom: '20px',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f8f9fa'
          }}>
            <img 
              src={imageURL} 
              alt={title}
              onLoad={() => setImageLoaded(true)}
              style={{ 
                maxWidth: '100%',
                maxHeight: '300px',
                objectFit: 'contain',
                display: imageLoaded ? 'block' : 'none'
              }}
            />
            {!imageLoaded && (
              <div style={{
                padding: '20px',
                textAlign: 'center',
                color: '#6c757d'
              }}>
                <i className="bi bi-image" style={{ fontSize: '2rem' }}></i>
                <p>Loading image...</p>
              </div>
            )}
          </div>
        )}

        {cooking_time && (
          <div className="recipe-section">
            <h3>Cooking Time</h3>
            <p><i className="bi bi-clock me-2"></i>{cooking_time}</p>
          </div>
        )}

        {servings.length > 0 && (
          <div className="recipe-section">
            <h3>Servings</h3>
            <ul>
              {servings.map((serving, index) => (
                <li key={index}>{serving}</li>
              ))}
            </ul>
          </div>
        )}

        {ingredients.length > 0 && (
          <div className="recipe-section">
            <h3>Ingredients</h3>
            <ul>
              {ingredients.map((ingredient, index) => (
                <li key={index}>{ingredient}</li>
              ))}
            </ul>
          </div>
        )}

        {instructions.length > 0 && (
          <div className="recipe-section">
            <h3>Instructions</h3>
            <ol>
              {instructions.map((instruction, index) => (
                <li key={index}>{instruction}</li>
              ))}
            </ol>
          </div>
        )}

        {cultural_context && (
          <div className="recipe-section">
            <h3>Cultural Context</h3>
            <p>{cultural_context}</p>
          </div>
        )}

        {tips.length > 0 && (
          <div className="recipe-section">
            <h3>Tips</h3>
            <ul>
              {tips.map((tip, index) => (
                <li key={index}>{tip}</li>
              ))}
            </ul>
          </div>
        )}

        <div className="recipe-actions">
          <button onClick={onDelete} className="btn btn-danger">Delete</button>
          <button onClick={onDownload} className="btn btn-primary">Download</button>
        </div>
      </div>
    </div>
  );
};

export default RecipeModal;
