import { supabase } from '../supabaseClient'
import SimpleIndexedDB from '../SimpleIndexedDB'
import SyncManager from './SyncManager'

export default class HybridStorage {
  constructor(tableName) {
    this.tableName = tableName
    this.localDB = new SimpleIndexedDB('AppDB', tableName)
    this.isUserProfile = tableName === 'user_profiles'
    this.syncInProgress = false
    this.lastSyncTime = null
    this.SYNC_INTERVAL = 1000 * 30 // 30 seconds
  }

  async initialize() {
    await this.localDB.openDB()
    const session = await this.initializeSession()
    
    if (!session) {
      console.log('No active session, skipping Supabase initialization')
      return
    }
    
    this.syncManager = new SyncManager(this.localDB, this.tableName)
    await this.syncManager.initialize()
    
    setTimeout(() => {
      this.syncWithSupabase()
    }, 1000)
    
    this.startPeriodicSync()
  }

  async initializeSession() {
    const { data: { session } } = await supabase.auth.getSession();
    this.session = session;
    
    if (!session) {
      console.log('No active session');
      return null;
    }

    return session;
  }

  startPeriodicSync() {
    setInterval(() => {
      if (!this.syncInProgress && this.shouldSync()) {
        this.syncWithSupabase()
      }
    }, this.SYNC_INTERVAL)
  }

  shouldSync() {
    if (!this.lastSyncTime) return true
    return Date.now() - this.lastSyncTime >= this.SYNC_INTERVAL
  }

  async syncWithSupabase() {
    if (this.syncInProgress) return
    this.syncInProgress = true

    try {
      await this.syncManager.performSync()
      this.lastSyncTime = new Date().toISOString()
    } catch (error) {
      console.error('Sync error:', error)
    } finally {
      this.syncInProgress = false
    }
  }

  async addItem(data) {
    try {
      if (data.type === 'userProfile') {
        return this.updateUserProfile(data);
      }

      const timestamp = new Date().toISOString();
      const itemWithSync = {
        ...data,
        syncStatus: 'pending',
        operation: 'insert',
        version: 1,
        uploadDateTime: timestamp,
        upload_date_time: timestamp
      };
      
      if (this.isUserProfile) {
        const { data: { session } } = await supabase.auth.getSession();
        if (session?.user?.id) {
          itemWithSync.user_id = session.user.id;
        }
      }
      
      if (data.imageBlob) {
        itemWithSync.imageBlob = data.imageBlob;
        itemWithSync.image_base64 = await this.blobToBase64(data.imageBlob);
      }
      
      const id = await this.localDB.addItem(itemWithSync);
      
      const itemWithId = {
        ...itemWithSync,
        id
      };
      
      if (this.syncManager) {
        await this.syncManager.queueChange(itemWithId);
      }
      
      return id;
    } catch (error) {
      console.error('Error adding item:', error);
      throw error;
    }
  }

  async getItem(id) {
    return await this.localDB.getItem(id)
  }

  async getAllItems() {
    return await this.localDB.getAllItems()
  }

  async updateItem(data) {
    try {
      console.log('Updating item with data:', data)
      const itemWithSync = {
        ...data,
        syncStatus: 'pending',
        operation: 'update',
        version: (data.version || 0) + 1,
        uploadDateTime: new Date().toISOString()
      }
      console.log('Item with sync status:', itemWithSync)
      await this.localDB.updateItem(itemWithSync)
      if (this.syncManager) {
        await this.syncManager.queueChange({
          ...itemWithSync,
          operation: 'update'
        })
      }
      return `Item with ID ${data.id} updated successfully.`
    } catch (error) {
      console.error('Error updating item:', error)
      throw error
    }
  }

  async deleteItem(id) {
    try {
        const existingItem = await this.localDB.getItem(id);
        if (!existingItem) {
            throw new Error(`Item with ID ${id} not found`);
        }

        // Create a delete record
        const deleteRecord = {
            id,
            operation: 'delete',
            syncStatus: 'pending',
            uploadDateTime: new Date().toISOString(),
            version: (existingItem.version || 0) + 1
        };
        
        // Queue the delete operation first
        if (this.syncManager) {
            console.log('Queueing delete operation for item:', id);
            await this.syncManager.queueChange(deleteRecord);
            
            // Trigger immediate sync
            await this.syncManager.performSync();
        }
        
        // Mark the item as pending delete in local DB
        await this.localDB.updateItem({
            ...existingItem,
            operation: 'delete',
            syncStatus: 'pending'
        });
        
        return `Item with ID ${id} queued for deletion.`;
    } catch (error) {
        console.error('Error deleting item:', error);
        throw error;
    }
  }

  async blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  async storeUserProfile(profile) {
    try {
      // Store in IndexedDB
      const existingItems = await this.localDB.getAllItems();
      const existingProfile = existingItems.find(item => item.type === 'userProfile');
      
      if (existingProfile) {
        await this.localDB.updateItem({
          ...existingProfile,
          ...profile,
          type: 'userProfile'
        });
      } else {
        await this.localDB.addItem({
          ...profile,
          type: 'userProfile'
        });
      }
    } catch (error) {
      console.error('Error storing user profile:', error);
      throw error;
    }
  }

  async getUserProfile() {
    try {
      const items = await this.localDB.getAllItems();
      return items.find(item => item.type === 'userProfile');
    } catch (error) {
      console.error('Error getting user profile:', error);
      return null;
    }
  }

  async updateUserProfile(profile) {
    try {
        // Don't store user profiles in the food_items table
        // Instead, directly update Supabase
        const { data: { session } } = await supabase.auth.getSession();
        if (!session?.user?.id) {
            throw new Error('No authenticated user');
        }

        const { data, error } = await supabase
            .from('user_profiles')
            .upsert({
                ...profile,
                user_id: session.user.id
            })
            .select()
            .single();

        if (error) throw error;
        return data;
    } catch (error) {
        console.error('Error updating user profile:', error);
        throw error;
    }
  }
}