/*The warpper for SImpleIndexedDB for persisitency across every component 
By using "const db = useDB", you will get a SimpleIndexedDB obecjt for use


*/
import React, { createContext, useState, useContext, useEffect } from 'react';
import HybridStorage from './services/HybridStorage';

// Create the context 
const DBContext = createContext(null);

// Export a hook to use the DBContext easily in components
export const useDB = () => {
  return useContext(DBContext);
};

// The DBProvider component to wrap your application and provide the IndexedDB instance
export const DBProvider = ({ children }) => {
  const [dbInstance, setDbInstance] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initDB = async () => {
      try {
        const storage = new HybridStorage('food_items');
        await storage.initialize();
        setDbInstance(storage);
      } catch (err) {
        console.error('Failed to initialize database:', err);
        setError(err);
      } finally {
        setIsInitialized(true);
      }
    };
    initDB();
  }, []);

  if (!isInitialized) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error initializing database. Please try refreshing the page.</div>;
  }

  return (
    <DBContext.Provider value={dbInstance}>
      {children}
    </DBContext.Provider>
  );
};
