import React, { useState } from 'react';
import { motion } from 'framer-motion';

const DietaryPreferencesStep = ({ data, onNext, onBack }) => {
  const [formData, setFormData] = useState({
    dietary_requirements: data.dietary_requirements || '',
    allergies: data.allergies || '',
    preferred_cuisines: data.preferred_cuisines || []
  });

  const cuisineOptions = [
    'Italian', 'Asian', 'Mexican', 'Mediterranean', 
    'Indian', 'American', 'Middle Eastern', 'Japanese'
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext(formData);
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -20 }}
    >
      <form onSubmit={handleSubmit} className="needs-validation">
        <div className="mb-4">
          <label className="form-label">
            Dietary Requirements
            <i className="bi bi-info-circle ms-2" data-bs-toggle="tooltip" data-bs-placement="top" 
               title="Adding preferences here will help generate recipes that better suit your requirements." />
          </label>
          <input
            type="text"
            className="form-control form-control-lg"
            value={formData.dietary_requirements}
            onChange={(e) => setFormData(prev => ({ ...prev, dietary_requirements: e.target.value }))}
            placeholder="E.g., vegetarian, vegan, gluten-free"
          />
        </div>

        <div className="mb-4">
          <label className="form-label">
            Allergies
            <i className="bi bi-info-circle ms-2" data-bs-toggle="tooltip" data-bs-placement="top" 
               title="Listing allergies here will trigger alerts when you upload images of food that potentially contain these allergens." />
          </label>
          <input
            type="text"
            className="form-control form-control-lg"
            value={formData.allergies}
            onChange={(e) => setFormData(prev => ({ ...prev, allergies: e.target.value }))}
            placeholder="E.g., peanuts, shellfish"
          />
        </div>

        <div className="d-flex justify-content-between mt-4">
          <button 
            type="button" 
            className="btn btn-outline-secondary" 
            onClick={onBack}
          >
            Back
          </button>
          <button 
            type="submit" 
            className="btn btn-primary"
          >
            Next
          </button>
        </div>
      </form>
    </motion.div>
  );
};

export default DietaryPreferencesStep; 