import React, { useState } from 'react';
import { motion } from 'framer-motion';

const BasicInfoStep = ({ data, onNext, onBack, isFirstStep }) => {
  const activityLevels = [
    { value: "sedentary", label: "Sedentary", description: "Little or no exercise" },
    { value: "lightly active", label: "Lightly Active", description: "Light exercise 1-3 days/week" },
    { value: "moderately active", label: "Moderately Active", description: "Moderate exercise 3-5 days/week" },
    { value: "very active", label: "Very Active", description: "Hard exercise 6-7 days a week" },
    { value: "super active", label: "Super Active", description: "Very hard exercise & physical job or 2x training" },
  ];

  const [formData, setFormData] = useState({
    name: data.name || '',
    sex: data.sex || '',
    age: data.age || '',
    height: data.height || '',
    weight: data.weight || '',
    activity_level: data.activity_level || ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext(formData);
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -20 }}
      className="basic-info-step"
    >
      <form onSubmit={handleSubmit} className="needs-validation">
        <div className="mb-4">
          <label className="form-label">Name</label>
          <input
            type="text"
            className="form-control form-control-lg"
            value={formData.name}
            onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
            required
          />
        </div>

        <div className="mb-4">
          <label className="form-label">Sex</label>
          <select
            className="form-select form-select-lg"
            value={formData.sex}
            onChange={(e) => setFormData(prev => ({ ...prev, sex: e.target.value }))}
            required
          >
            <option value="">Select sex</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="form-label">Age</label>
          <input
            type="number"
            className="form-control form-control-lg"
            value={formData.age}
            onChange={(e) => setFormData(prev => ({ ...prev, age: e.target.value }))}
            min="1"
            max="120"
            required
          />
        </div>

        <div className="mb-4">
          <label className="form-label">Height (cm)</label>
          <input
            type="number"
            className="form-control form-control-lg"
            value={formData.height}
            onChange={(e) => setFormData(prev => ({ ...prev, height: e.target.value }))}
            min="1"
            max="300"
            required
          />
        </div>

        <div className="mb-4">
          <label className="form-label">Weight (kg)</label>
          <input
            type="number"
            className="form-control form-control-lg"
            value={formData.weight}
            onChange={(e) => setFormData(prev => ({ ...prev, weight: e.target.value }))}
            min="1"
            max="500"
            required
          />
        </div>

        <div className="mb-4">
          <label className="form-label">Activity Level</label>
          <select
            className="form-select form-select-lg"
            value={formData.activity_level}
            onChange={(e) => setFormData(prev => ({ ...prev, activity_level: e.target.value }))}
            required
          >
            <option value="">Select activity level</option>
            {activityLevels.map(level => (
              <option key={level.value} value={level.value}>
                {level.label} - {level.description}
              </option>
            ))}
          </select>
        </div>

        <div className="d-flex justify-content-between mt-4">
          {!isFirstStep && (
            <button 
              type="button" 
              className="btn btn-outline-secondary" 
              onClick={onBack}
            >
              Back
            </button>
          )}
          <button 
            type="submit" 
            className="btn btn-primary ms-auto"
          >
            Next
          </button>
        </div>
      </form>
    </motion.div>
  );
};

export default BasicInfoStep; 