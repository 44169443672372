import React from 'react';

const RecipeCard = ({ recipe, onClick, onDelete, onDownload }) => {
  if (!recipe) {
    return <div>No recipe available</div>;
  }

  return (
    <div className="recipe-card" onClick={onClick}>
      {recipe.imageURL && (
        <div className="recipe-image">
          <img src={recipe.imageURL} alt={recipe.title} />
        </div>
      )}
      <div className="recipe-content">
        <h3>{recipe.title}</h3>
        {recipe.cooking_time && (
          <p className="cooking-time">
            <i className="bi bi-clock me-2"></i>
            {recipe.cooking_time}
          </p>
        )}
        <div className="recipe-actions">
          <button 
            onClick={(e) => {
              e.stopPropagation();
              onDelete(recipe.id);
            }}
            className="btn btn-sm btn-outline-danger"
          >
            <i className="bi bi-trash"></i>
          </button>
          <button 
            onClick={(e) => {
              e.stopPropagation();
              onDownload(recipe);
            }}
            className="btn btn-sm btn-outline-primary ms-2"
          >
            <i className="bi bi-download"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecipeCard;
