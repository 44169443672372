export const DEFAULT_PROFILE = {
  name: '',
  sex: '',
  age: '',
  height: '',
  weight: '',
  activity_level: '',
  dietary_requirements: '',
  allergies: '',
  rdi: {
    calories: 2000,
    carbohydrates: 250,
    protein: 50,
    fats: 70,
    fiber: 30,
    sugar: 50,
    cholesterol: 300,
    sodium: 2300
  }
};

// Fields that exist in the database schema
export const VALID_PROFILE_FIELDS = [
  'name',
  'sex',
  'age',
  'height',
  'weight',
  'activity_level',
  'dietary_requirements',
  'allergies',
  'rdi'
]; 