import OpenAI from 'openai' // Make sure to install the openai package

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY, // Store your API key in .env file
  baseURL: "https://oai.helicone.ai/v1",
  defaultHeaders: {
    "Helicone-Auth": "Bearer pk-helicone-lg4yufy-ij7ecwy-rdblkoy-khkxkai",
  },
  dangerouslyAllowBrowser: true,
})

export const analyzeImage = async (dataURL, description = '', allergies = '') => {
  try {
    console.log('Analyzing image with allergies:', allergies);
    const response = await openai.chat.completions.create({
      model: 'gpt-4o-2024-08-06',
      messages: [
        {
          role: 'system',
          content: 'You are a helpful assistant that analyzes food images, describes the dish including all elements in intricate detail, and determines its nutritional content.',
        },
        {
          role: 'user',
          content: [
            {
              type: 'text',
              text: `Analyze this food image and provide information in the specified JSON format. ${description ? 'Please consider this additional information from the user: ' + description : ''}
              Please pay special attention to the presence of these allergens: ${allergies}. If any of these allergens are present or likely to be present, make sure to set is_allergen_present to true and include them in the allergens_found array.`,
            },
            {
              type: 'image_url',
              image_url: {
                url: dataURL,
              },
            },
          ],
        },
      ],
      response_format: {
        type: 'json_schema',
        json_schema: {
          name: 'food_analysis',
          schema: {
            type: 'object',
            properties: {
              name: { type: 'string' },
              short_name: { type: 'string', description: 'A concise name (max 6 words) for the food item' },
              description: { type: 'string', description: 'A detailed description for the food item' },
              calories: { type: 'number' },
              serving_size: { type: 'string' },
              confidence_score: { type: 'number' },
              macronutrients: {
                type: 'object',
                properties: {
                  protein: { type: 'string' },
                  carbohydrates: { type: 'string' },
                  fats: { type: 'string' },
                  fiber: { type: 'string' },
                  sugar: { type: 'string' },
                },
                required: ['protein', 'carbohydrates', 'fats', 'fiber', 'sugar'],
                additionalProperties: false,
              },
              cholesterol: { type: 'string' },
              sodium: { type: 'string' },
              exercise_equivalent: {
                type: 'object',
                properties: {
                  walking_minutes: { type: 'number' },
                  notes: { type: 'string' },
                },
                required: ['walking_minutes', 'notes'],
                additionalProperties: false,
              },
              allergy_warning: {
                type: 'object',
                properties: {
                  is_allergen_present: { type: 'boolean' },
                  allergens_found: { type: 'array', items: { type: 'string' } },
                  notes: { type: 'string' },
                },
                required: ['is_allergen_present', 'allergens_found', 'notes'],
                additionalProperties: false,
              },
            },
            required: [
              'name',
              'short_name',
              'description',
              'calories',
              'serving_size',
              'confidence_score',
              'macronutrients',
              'cholesterol',
              'sodium',
              'exercise_equivalent',
              'allergy_warning',
            ],
            additionalProperties: false,
          },
          strict: true,
        },
      },
      max_tokens: 500,
      temperature: 0.5,
    });

    console.log('OpenAI API Response:', response);

    if (response.choices[0].message.refusal) {
      console.log('Model refused to respond:', response.choices[0].message.refusal);
      return 'Model refused to analyze the image.';
    }

    const initialAnalysis = JSON.parse(response.choices[0].message.content);
    console.log('Parsed analysis result:', initialAnalysis);

    return initialAnalysis;
  } catch (error) {
    console.error('Error analyzing image:', error);
    return 'Unable to analyze image';
  }
};

export const generateRecipe = async (foodName, description = '', dietaryRequirements = '') => {
  try {
    const response = await openai.chat.completions.create({
      model: 'gpt-4o-2024-08-06',
      messages: [
        {
          role: 'system',
          content: 'You are a helpful assistant that provides recipe suggestions based on food descriptions and dietary requirements. Always use metric units for measurements.',
        },
        {
          role: 'user',
          content: `Generate a detailed recipe for ${foodName}. ${description ? 'Additional details: ' + description : ''}
          ${dietaryRequirements ? `Please ensure the recipe meets the following dietary requirements (if any): ${dietaryRequirements}` : ''}
          Consider the following:
          1. If we asked a huge sample of the global population what culture or group of people makes this dish best, what would they say?
          2. If we asked those people or culture who amongst them makes it best, what would they say?
          3. If we asked that person for the recipe and detailed instructions on best ingredients and processes, what would it be?
          4. Do not directly answer these questions, instead, use the information to generate a detailed recipe.
          5. The recipe should be a detailed step by step guide with ingredients, number of servings, detailed instructions, equipment and measurements.
          6. The recipe should also include any cultural context or tips.
          7. The recipe should be in a language that is easy to understand and follow.
          8. Ensure the recipe adheres to the specified dietary requirements, if any.
          9. Include an estimated cooking time for the recipe in this format: "1h 30m" or "30m" or "1h".
          10. Include the number of servings in this format: "Serves 1" or "4 pieces" or "8 burgers".
          11. Use metric units for all measurements (e.g., grams, milliliters, centimeters, Celsius).`,
        },
      ],
      response_format: {
        type: 'json_schema',
        json_schema: {
          name: 'recipe',
          schema: {
            type: 'object',
            properties: {
              title: { type: 'string' },
              ingredients: { type: 'array', items: { type: 'string' } },
              servings: { type: 'array', items: { type: 'string' } },
              instructions: { type: 'array', items: { type: 'string' } },
              cultural_context: { type: 'string' },
              tips: { type: 'array', items: { type: 'string' } },
              cooking_time: { type: 'string' },
            },
            required: ['title', 'servings', 'ingredients', 'instructions', 'cultural_context', 'tips', 'cooking_time'],
            additionalProperties: false,
          },
          strict: true,
        },
      },
      max_tokens: 1000,
      temperature: 0.7,
    })

    console.log('OpenAI API Response (Recipe):', response)

    if (response.choices[0].message.refusal) {
      console.log('Model refused to respond:', response.choices[0].message.refusal)
      return {
        title: foodName || 'Untitled Recipe',
        servings: ['1 serving'],
        ingredients: [],
        instructions: [],
        cultural_context: 'Unable to generate recipe details',
        tips: [],
        cooking_time: 'Not specified'
      };
    }

    const parsedRecipe = JSON.parse(response.choices[0].message.content);
    return {
      title: parsedRecipe.title || foodName || 'Untitled Recipe',
      servings: Array.isArray(parsedRecipe.servings) ? parsedRecipe.servings : ['1 serving'],
      ingredients: Array.isArray(parsedRecipe.ingredients) ? parsedRecipe.ingredients : [],
      instructions: Array.isArray(parsedRecipe.instructions) ? parsedRecipe.instructions : [],
      cultural_context: parsedRecipe.cultural_context || '',
      tips: Array.isArray(parsedRecipe.tips) ? parsedRecipe.tips : [],
      cooking_time: parsedRecipe.cooking_time || 'Not specified'
    };
  } catch (error) {
    console.error('Error generating recipe:', error);
    return {
      title: foodName || 'Untitled Recipe',
      servings: ['1 serving'],
      ingredients: [],
      instructions: [],
      cultural_context: 'Error generating recipe',
      tips: [],
      cooking_time: 'Not specified'
    };
  }
};

export const analyzeMicronutrients = async (foodName, description = '') => {
  try {
    const response = await openai.chat.completions.create({
      model: 'gpt-4o-2024-08-06',
      messages: [
        {
          role: 'system',
          content: 'You are a helpful assistant that provides detailed micronutrient information for foods in JSON format.',
        },
        {
          role: 'user',
          content: `Provide detailed micronutrient information for ${foodName} in JSON format. ${description ? 'Additional details: ' + description : ''}
          Include information on vitamins (A, C, D, E, K, B-complex) and minerals (calcium, iron, magnesium, potassium, zinc, selenium, etc.).
          Provide amounts per 100g of the food. Return the response as a JSON object with the following structure:
          {
            "food_name": "Name of the food",
            "serving_size": "100g",
            "micronutrients": {
              "Vitamin A": "0mcg",
              "Vitamin C": "0mg",
              "Vitamin D": "0mcg",
              "Vitamin E": "0mg",
              "Vitamin K": "0mcg",
              "Vitamin B1 (Thiamin)": "0mg",
              "Vitamin B2 (Riboflavin)": "0mg",
              "Vitamin B3 (Niacin)": "0mg",
              "Vitamin B6": "0mg",
              "Vitamin B12": "0mcg",
              "Folate": "0mcg",
              "Calcium": "0mg",
              "Iron": "0mg",
              "Magnesium": "0mg",
              "Potassium": "0mg",
              "Zinc": "0mg",
              "Selenium": "0mcg"
            }
          }
          Replace the 0 values with the actual amounts in appropriate units (e.g., mg, mcg, IU) for 100g of the food.`,
        },
      ],
      response_format: { type: 'json_object' },
      max_tokens: 500,
      temperature: 0.5,
    });

    console.log('OpenAI API Response (Micronutrients):', response);

    if (response.choices[0].message.content) {
      const result = JSON.parse(response.choices[0].message.content);
      return result;
    } else {
      throw new Error('No content in the response');
    }
  } catch (error) {
    console.error('Error analyzing micronutrients:', error);
    throw error;
  }
};

export const categorizeMealTime = (uploadDateTime) => {
  const date = new Date(uploadDateTime);
  const hour = date.getHours();

  if (hour >= 5 && hour < 10) return 'Breakfast';
  if (hour >= 10 && hour < 12) return 'Brunch';
  if (hour >= 12 && hour < 15) return 'Lunch';
  if (hour >= 15 && hour < 18) return 'Afternoon Snack';
  if (hour >= 18 && hour < 21) return 'Dinner';
  if (hour >= 21 || hour < 5) return 'Night Snack';

  return 'Other';
};

