import React, { useState } from 'react';

import { analyzeMicronutrients } from '../services/analyzeImage';

const MicronutrientsSummary = ({ consumedFoodList, rdi }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [micronutrientData, setMicronutrientData] = useState(null);

  // Calculate total micronutrients from consumedFoodList
  const totalMicronutrients = consumedFoodList.reduce((acc, food) => {
    // Assuming each food item has a micronutrients property
    Object.entries(food.micronutrients || {}).forEach(([nutrient, amount]) => {
      acc[nutrient] = (acc[nutrient] || 0) + amount;
    });
    return acc;
  }, {});

  const micronutrients = [
    { name: 'Vitamin A', unit: 'mcg' },
    { name: 'Vitamin C', unit: 'mg' },
    { name: 'Vitamin D', unit: 'mcg' },
    { name: 'Vitamin E', unit: 'mg' },
    { name: 'Vitamin K', unit: 'mcg' },
    { name: 'Vitamin B1 (Thiamin)', unit: 'mg' },
    { name: 'Vitamin B2 (Riboflavin)', unit: 'mg' },
    { name: 'Vitamin B3 (Niacin)', unit: 'mg' },
    { name: 'Vitamin B6', unit: 'mg' },
    { name: 'Vitamin B12', unit: 'mcg' },
    { name: 'Folate', unit: 'mcg' },
    { name: 'Calcium', unit: 'mg' },
    { name: 'Iron', unit: 'mg' },
    { name: 'Magnesium', unit: 'mg' },
    { name: 'Potassium', unit: 'mg' },
    { name: 'Zinc', unit: 'mg' },
    { name: 'Selenium', unit: 'mcg' },
  ];

  const handleAnalyzeMicronutrients = async () => {
    setLoading(true);
    setError(null);
    try {
      const lastConsumedFood = consumedFoodList[consumedFoodList.length - 1];
      if (!lastConsumedFood) {
        throw new Error('No food items in consumption history.');
      }

      const result = await analyzeMicronutrients(lastConsumedFood.name, lastConsumedFood.description);
      setMicronutrientData(result);
    } catch (err) {
      setError(`Failed to analyze micronutrients: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="micronutrients-summary">
      <button
        className="btn btn-primary mb-3"
        onClick={handleAnalyzeMicronutrients}
        disabled={loading || consumedFoodList.length === 0}
      >
        {loading ? 'Analyzing...' : 'Analyze Micronutrients of Last Consumed Item'}
      </button>

      {error && <div className="alert alert-danger">{error}</div>}

      {micronutrientData && (
        <div className="alert alert-info">
          Showing micronutrient analysis for: {micronutrientData.food_name} (per {micronutrientData.serving_size})
        </div>
      )}

      <table className="table">
        <thead>
          <tr>
            <th>Nutrient</th>
            <th>Amount</th>
            {rdi && <th>RDI</th>}
            {rdi && <th>% of RDI</th>}
          </tr>
        </thead>
        <tbody>
          {micronutrients.map((nutrient) => {
            const amount = micronutrientData
              ? micronutrientData.micronutrients[nutrient.name]
              : (totalMicronutrients[nutrient.name] || 0);

            // Convert amount to a number if it's a string, or use 0 if it's undefined
            const numericAmount = typeof amount === 'string' ? parseFloat(amount) : (amount || 0);

            const rdiAmount = rdi?.[nutrient.name] || 0;
            const percentage = rdiAmount ? (numericAmount / rdiAmount) * 100 : 0;

            return (
              <tr key={nutrient.name}>
                <td>{nutrient.name}</td>
                <td>{numericAmount.toFixed(2)} {nutrient.unit}</td>
                {rdi && <td>{rdiAmount} {nutrient.unit}</td>}
                {rdi && <td>{percentage.toFixed(1)}%</td>}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MicronutrientsSummary;
