import { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import './css/Auth.scss';
import { useNavigate } from 'react-router-dom';
import { TrialManager } from '../services/TrialManager';

export default function Auth() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [authMode, setAuthMode] = useState('sign-in');
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const remainingTrials = TrialManager.getRemainingTrials();

  const handleEmailAuth = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(null);

    try {
      if (authMode === 'sign-in') {
        const { error } = await supabase.auth.signInWithPassword({
          email,
          password,
        });
        if (error) throw error;
      } else {
        // Check if passwords match for sign up
        if (password !== confirmPassword) {
          throw new Error('Passwords do not match');
        }
        
        const { error } = await supabase.auth.signUp({
          email,
          password,
          options: {
            emailRedirectTo: `${window.location.origin}/auth/callback`,
          },
        });
        if (error) throw error;
        setMessage('Check your email for the confirmation link!');
      }
    } catch (error) {
      setMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleMagicLink = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(null);

    try {
      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          emailRedirectTo: `${window.location.origin}/auth/callback`,
        },
      });
      if (error) throw error;
      setMessage('Check your email for the magic link!');
    } catch (error) {
      setMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleAuthCallback = async () => {
      try {
        // Get the hash from the URL if it exists
        const hashParams = new URLSearchParams(window.location.hash.substring(1))
        const accessToken = hashParams.get('access_token')
        const refreshToken = hashParams.get('refresh_token')

        if (accessToken && refreshToken) {
          // Set the session manually if tokens are present
          await supabase.auth.setSession({
            access_token: accessToken,
            refresh_token: refreshToken
          })
        }

        // Get the session to verify
        const { data: { session }, error } = await supabase.auth.getSession()
        
        if (error) throw error

        if (session) {
          console.log('Authentication successful:', session)
          // Redirect to your app's main page
          navigate('/')
        } else {
          console.error('No session found')
          navigate('/login')
        }
      } catch (error) {
        console.error('Auth callback error:', error)
        navigate('/login')
      }
    }

    handleAuthCallback()
  }, [navigate])

  return (
    <div className="auth-container">
      <div className="auth-form">
        <h1>{authMode === 'sign-in' ? 'Sign In' : 'Sign Up'}</h1>
        {message && <div className="auth-message">{message}</div>}
        
        {remainingTrials > 0 ? (
          <>
            <div className="alert alert-info mb-4">
              <h4 className="alert-heading">Try Before You Sign Up!</h4>
              <p>You have {remainingTrials} free trial analyses remaining. Sign up to unlock unlimited analyses and save your data!</p>
            </div>
            <button 
              className="btn btn-success btn-lg w-100 mb-4"
              onClick={() => navigate('/')}
            >
              Try Now
            </button>
          </>
        ) : (
          <div className="alert alert-warning mb-4">
            <h4 className="alert-heading">Trial Completed</h4>
            <p>You've used all your trial analyses. Sign up now to continue using CalChef and access all features!</p>
          </div>
        )}
        
        <form onSubmit={handleEmailAuth}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {authMode === 'sign-up' && (
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          )}
          <button type="submit" disabled={loading}>
            {loading ? 'Loading...' : authMode === 'sign-in' ? 'Sign In' : 'Sign Up'}
          </button>
        </form>

        <div className="auth-divider">
          <span>OR</span>
        </div>

        <button onClick={handleMagicLink} className="magic-link-button" disabled={loading}>
          Sign in with Magic Link
        </button>

        <p className="auth-switch">
          {authMode === 'sign-in' ? (
            <>
              Don't have an account?{' '}
              <button onClick={() => {
                setAuthMode('sign-up');
                setMessage(null);
                setPassword('');
                setConfirmPassword('');
              }}>
                Sign Up
              </button>
            </>
          ) : (
            <>
              Already have an account?{' '}
              <button onClick={() => {
                setAuthMode('sign-in');
                setMessage(null);
                setPassword('');
                setConfirmPassword('');
              }}>
                Sign In
              </button>
            </>
          )}
        </p>
      </div>
    </div>
  );
}

