import React from 'react';
import { nutritionTypes } from '../ConsumedFood';

const NutritionSummary = ({ consumedFoodList, rdi, onDateChange, isDaily, leftNavRef, rightNavRef, isExpanded, setIsExpanded }) => {
  const calculateTotal = (nutrient) => {
    return consumedFoodList.reduce((sum, item) => {
      if (nutrient === 'calories') {
        return sum + (item?.calories || 0);
      } else if (nutrient === 'cholesterol' || nutrient === 'sodium') {
        return sum + parseFloat(item?.[nutrient] || 0);
      } else {
        return sum + parseFloat(item?.macronutrients?.[nutrient] || 0);
      }
    }, 0);
  };

  const calculatePercentage = (value, total) => Math.min((value / total) * 100, 100);

  const nutrients = [
    { name: 'Calories', unit: 'kcal', rdiKey: 'calories', icon: '🔥' },
    { name: 'Protein', unit: 'g', rdiKey: 'protein', icon: '💪' },
    { name: 'Carbs', unit: 'g', rdiKey: 'carbohydrates', icon: '🍞' },
    { name: 'Fat', unit: 'g', rdiKey: 'fats', icon: '🥑' },
    { name: 'Fiber', unit: 'g', rdiKey: 'fiber', icon: '🥦' },
    { name: 'Sugar', unit: 'g', rdiKey: 'sugar', icon: '🍭' },
    { name: 'Cholesterol', unit: 'mg', rdiKey: 'cholesterol', icon: '🥚' },
    { name: 'Sodium', unit: 'mg', rdiKey: 'sodium', icon: '🧂' },
  ];

  const totalCalories = calculateTotal('calories');
  const remainingCalories = Math.max(0, (rdi?.calories || 2000) - totalCalories);
  const caloriePercentage = calculatePercentage(totalCalories, rdi?.calories || 2000);

  const handleDateChange = (direction) => {
    if (typeof onDateChange === 'function') {
      onDateChange(direction);
    } else {
      console.error('onDateChange is not a function');
    }
  };

  return (
    <div className={`nutrition-dashboard ${isExpanded ? 'expanded' : 'collapsed'}`}>
      <div className="total-calories-display" onClick={() => setIsExpanded(!isExpanded)}>
        <div ref={leftNavRef} className="calories-navigation left ripple" onClick={(e) => { e.stopPropagation(); handleDateChange('previous'); }}>
          <i className="bi bi-chevron-left"></i>
        </div>
        <div ref={rightNavRef} className="calories-navigation right ripple" onClick={(e) => { e.stopPropagation(); handleDateChange('next'); }}>
          <i className="bi bi-chevron-right"></i>
        </div>
        <div className="calories-content">
          <div className="calories-circle" style={{ '--percentage': `${caloriePercentage}%` }}>
            <div className="calories-inner">
              <span className="calories-number">{totalCalories.toFixed(0)}</span>
              <span className="calories-unit">kcal</span>
            </div>
          </div>
          <div className="calories-info">
            <h3>{isDaily ? 'Daily' : 'Weekly'} Calories</h3>
            <p>of {rdi?.calories || 2000} kcal {isDaily ? 'daily' : 'weekly'} goal</p>
            <p className="calories-percentage">
              {remainingCalories.toFixed(0)} kcal remaining
            </p>
          </div>
        </div>
        <div className="expand-icon">
          <i className={`bi bi-chevron-${isExpanded ? 'up' : 'down'}`}></i>
        </div>
      </div>

      {isExpanded && (
        <div className="nutrient-summary compact">
          {nutrients.slice(1).map((nutrient) => {
            const total = calculateTotal(nutrient.rdiKey);
            const rdiValue = rdi?.[nutrient.rdiKey] ?? 100;
            const percentage = calculatePercentage(total, rdiValue);

            return (
              <div key={nutrient.name} className="nutrient-item">
                <div className="nutrient-icon">{nutrient.icon}</div>
                <div className="nutrient-info">
                  <div className="nutrient-name">{nutrient.name}</div>
                  <div className="nutrient-values">
                    <span>{total.toFixed(1)}/{rdiValue}{nutrient.unit}</span>
                    <span className="nutrient-percentage">{percentage.toFixed(0)}%</span>
                  </div>
                </div>
                <div className="nutrient-bar" style={{ width: `${percentage}%` }}></div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default NutritionSummary;
