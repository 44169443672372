export default class SimpleIndexedDB {
  constructor(dbName, storeName) {
    this.dbName = dbName
    this.storeName = storeName
    this.db = null
  }

  async getCurrentVersion() {
    return new Promise((resolve) => {
      const request = indexedDB.open(this.dbName);
      request.onsuccess = (event) => {
        const db = event.target.result;
        const version = db.version;
        db.close();
        resolve(version);
      };
      request.onerror = () => resolve(1); // Default to 1 if we can't get the current version
    });
  }

  /**
   * Opens the database. If the database or object store does not exist, it will be created.
   * @param {number} [version=null] - The version of the database (used to upgrade).
   * @returns {Promise} - Resolves with the opened database instance.
   */
  async openDB(version = null) {
    try {
      // If no version specified, get the current version or use a higher one
      if (!version) {
        version = await this.getCurrentVersion();
        // Increment version to trigger onupgradeneeded
        version += 1;
      }

      return new Promise((resolve, reject) => {
        const request = indexedDB.open(this.dbName, version);

        request.onerror = (event) => {
          console.error('Database error:', event.target.error);
          reject(new Error(`Failed to open the database: ${event.target.error.message}`));
        };

        request.onupgradeneeded = (event) => {
          const db = event.target.result;
          
          // Check if store exists before creating
          if (!db.objectStoreNames.contains(this.storeName)) {
            console.log(`Creating store: ${this.storeName}`);
            db.createObjectStore(this.storeName, {
              keyPath: 'id',
              autoIncrement: true,
            });
          }
        };

        request.onsuccess = (event) => {
          this.db = event.target.result;
          console.log(`Database opened successfully: ${this.dbName}`);
          console.log('Available stores:', Array.from(this.db.objectStoreNames));
          
          this.db.onerror = (event) => {
            console.error('Database error:', event.target.error);
          };
          
          resolve(this.db);
        };
      });
    } catch (error) {
      console.error('Critical database error:', error);
      throw new Error(`Failed to initialize database: ${error.message}`);
    }
  }

  /**
   * Adds a new item to the object store.
   * @param {Object} data - The data object to store.
   * @returns {Promise} - Resolves with the ID of the added item.
   */
  addItem(data) {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        reject(new Error('Database not initialized'));
        return;
      }

      const transaction = this.db.transaction([this.storeName], 'readwrite');
      const store = transaction.objectStore(this.storeName);

      const request = store.add(data);

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = (event) => {
        reject(new Error(`Failed to add item: ${event.target.error}`));
      };

      transaction.oncomplete = () => {
        console.log('Transaction completed: Item added successfully');
      };

      transaction.onerror = (event) => {
        console.error('Transaction error:', event.target.error);
      };
    });
  }

  /**
   * Retrieves an item from the object store by its ID.
   * @param {number} id - The ID of the item to retrieve.
   * @returns {Promise} - Resolves with the retrieved item.
   */
  getItem(id) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readonly')
      const store = transaction.objectStore(this.storeName)

      const request = store.get(id)

      request.onsuccess = () => {
        resolve(request.result) // Return the data retrieved
      }

      request.onerror = (event) => {
        reject(`Failed to retrieve item: ${event.target.errorCode}`)
      }
    })
  }

  /**
   * Retrieves all items from the object store.
   * @returns {Promise} - Resolves with an array of all items in the store.
   */
  async getAllItems() {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readonly')
      const store = transaction.objectStore(this.storeName)

      const request = store.getAll()

      request.onsuccess = () => {
        const items = request.result
        // Process each item's image
        items.forEach((item) => {
          // Check for base64 image data from Supabase
          if (item.image_base64) {
            // Create blob from base64
            const byteString = atob(item.image_base64.split(',')[1]);
            const mimeString = item.image_base64.split(',')[0].split(':')[1].split(';')[0];
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            
            for (let i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            
            const blob = new Blob([ab], { type: mimeString });
            item.imageBlob = blob;
            item.imageURL = URL.createObjectURL(blob);
          }
          // Handle existing imageBlob
          else if (item.imageBlob) {
            item.imageURL = URL.createObjectURL(item.imageBlob);
          }
        });
        resolve(items);
      }

      request.onerror = (event) => {
        reject(`Failed to retrieve items: ${event.target.errorCode}`)
      }
    })
  }

  /**
   * Updates an existing item in the object store.
   * @param {Object} data - The updated data object, which must include an existing ID.
   * @returns {Promise} - Resolves with a success message.
   */
  updateItem(data) {
    return new Promise((resolve, reject) => {
      if (!data.id) {
        reject(new Error('No ID provided for update'));
        return;
      }

      const transaction = this.db.transaction([this.storeName], 'readwrite');
      const store = transaction.objectStore(this.storeName);

      // First, get the existing item
      const getRequest = store.get(data.id);

      getRequest.onsuccess = () => {
        const existingItem = getRequest.result;

        if (!existingItem) {
          reject(new Error(`Item with ID ${data.id} not found`));
          return;
        }

        // Preserve existing properties that shouldn't be overwritten
        const updatedData = {
          ...data,
          syncStatus: data.syncStatus || existingItem.syncStatus,
          imageBlob: data.imageBlob || existingItem.imageBlob
        };

        // If there's an imageBlob, create a new URL
        if (updatedData.imageBlob) {
          updatedData.imageURL = URL.createObjectURL(updatedData.imageBlob);
        }

        // Now update the item
        const updateRequest = store.put(updatedData);

        updateRequest.onsuccess = () => {
          resolve(`Item with ID ${data.id} updated successfully.`);
        };

        updateRequest.onerror = (event) => {
          reject(`Failed to update item: ${event.target.error}`);
        };
      };

      getRequest.onerror = (event) => {
        reject(`Failed to retrieve item for update: ${event.target.error}`);
      };
    });
  }

  /**
   * Deletes an item from the object store by its ID.
   * @param {number} id - The ID of the item to delete.
   * @returns {Promise} - Resolves with a success message.
   */
  deleteItem(id) {
    return new Promise((resolve, reject) => {
      if (id === undefined) {
        reject('No ID provided for deletion')
        return
      }

      const transaction = this.db.transaction([this.storeName], 'readwrite')
      const store = transaction.objectStore(this.storeName)

      const request = store.delete(id)

      request.onsuccess = () => {
        resolve(`Item with ID ${id} deleted successfully.`)
      }

      request.onerror = (event) => {
        reject(`Failed to delete item: ${event.target.error}`)
      }
    })
  }
}
