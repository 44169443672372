import React from 'react';
import { createRoot } from 'react-dom/client';
import './css/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/App.scss';
import './css/onboarding.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DBProvider } from './DBProvider';
import ReactGA from "react-ga4";
import { AuthProvider } from './AuthProvider';
import { BrowserRouter } from 'react-router-dom';
import { OnboardingProvider } from './contexts/OnboardingContext';
import { Toaster } from 'react-hot-toast';

// Add error boundary wrapper component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error:', error, errorInfo);
    ReactGA.event({
      category: 'Error',
      action: 'App Crash',
      label: error.toString()
    });
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Please try refreshing the page.</h1>;
    }
    return this.props.children;
  }
}

// Apply dark mode on initial load
const isDarkMode = localStorage.getItem('darkMode') === 'true';
if (isDarkMode) {
  document.documentElement.setAttribute('data-bs-theme', 'dark');
} else {
  document.documentElement.setAttribute('data-bs-theme', 'light');
}

// Initialize GA4
ReactGA.initialize("G-FTV0Q2D1H7"); // Replace with your actual GA4 Measurement ID

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <AuthProvider>
          <DBProvider>
            <OnboardingProvider>
              <App />
              <Toaster />
            </OnboardingProvider>
          </DBProvider>
        </AuthProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);

reportWebVitals();

